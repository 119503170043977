<template>
    <div class="reset-password">
        <div class="title">
            <div class="sub-title">找回密码</div> 
        </div>
        <el-card class="box-card" style="min-height:100%">
            <div class="forget flex">
                <div class="warpper">
                    <div class="progress flex">
                        <div class="progress-item flex" :class="stateList.includes(1)?'active3':''">
                            <div class="number">1</div>
                            <div class="title3">确认手机号码</div>
                        </div>
                        <div class="line"></div>
                        <div class="progress-item flex" :class="stateList.includes(2)?'active3':''">
                            <div class="number">2</div>
                            <div class="title3">确认身份</div>
                        </div>
                        <div class="line"></div>
                        <div class="progress-item flex" :class="stateList.includes(3)?'active3':''">
                            <div class="number">3</div>
                            <div class="title3">重置密码</div>
                        </div>
                    </div>
                    <div class="step-warpper">
                        <div class="first-step" v-if="state === 1">
                            <div class="flex t-count">
                                <div class="name">手机号码: </div>
                                <el-input v-model="resetForm.phone" placeholder="请输入手机号码" style="width: 300px" type="number"></el-input>
                            </div>
                            <div>
                                <el-button class="active btn" @click="toStep2">提 交</el-button>
                            </div>
                        </div>
                        <div class="second-step" v-if="state === 2">
                            <div class="flex message">
                                <div class="phone">手机号码:</div>
                                <div class="phone-number">{{filterPhone}}</div>
                                <el-button>发送验证码</el-button>
                            </div>
                            <div class="flex code">
                                <div class="name flex">验证号码: </div>
                                <el-input v-model="resetForm.code" placeholder="请输入手机号码" style="width: 300px" type="number"></el-input>
                            </div>
                            <div>
                                <el-button class="active btn" @click="toStep3">下一步</el-button>
                            </div>
                        </div>
                        <div class="third-step" v-if="state === 3">
                            <div class="password flex">
                                <div class="title5">密码:</div>
                                <el-input v-model="resetForm.phone" placeholder="请输入手机号码" style="width: 300px"></el-input>
                            </div>
                            <div class="again-password flex">
                                <div class="title5">确认密码:</div>
                                <el-input v-model="resetForm.phone" placeholder="请输入手机号码" style="width: 300px"></el-input>
                            </div>
                            <div>
                                <el-button class="active btn">确认</el-button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </el-card>
    </div>
</template>
<script>
export default {
    data() {
        return {
            state: 1,
            stateList: [1],
            resetForm: {
                phone: '',
                code: ''
            }
        }
    },
    methods: {
        toStep2() {
            this.stateList.push(2)
            this.state = 2
        },
        toStep3() {
            this.stateList.push(3)
            this.state = 3
        }
    },
    computed: {
        filterPhone() {
            return this.resetForm.phone.replace(/^(\d{3})\d{4}(\d+)/,"$1****$2")
        }
    }
}
</script>
<style lang="scss" scoped>
@import '../../../assets/css/global.scss';
.reset-password{
    height: 100%;
    .forget{
        width: 100%;
        margin-top: 50px;
        justify-content: center;
    }
    .progress{
        align-items: center;
        .progress-item{
            position: relative;
            flex-direction: column;
            align-items: center;
            &.active3{
                .number{
                    background: $main;
                }
                .title3{
                    color: $main;
                }
            }
            .number{
                width: 30px;
                height: 30px;
                line-height: 30px;
                text-align: center;
                color: #fff;
                border-radius: 50%;
                background: #ccc;
            }
            .title3{
                margin-top: 10px;
                color: #ccc;
                font-size: 12px;
            }
        }
        .line{
            align-self: center;
            width: 137px;
            height: 2px;
            background: #ccc;
            margin-top: -20px;
        }
    }
    .step-warpper{
        margin-top: 50px;
        .first-step{
            .t-count{
                align-items: center;
            }
            .name{
                margin-right: 15px;
            }
            .btn{
                margin-left: 74px;
                width: 300px;
                margin-top: 30px;
            }
        }
        .second-step{
            .message{
                margin-bottom: 20px;
                align-items: center;
                .phone{
                    margin-right: 15px;
                }
                .phone-number{
                    margin-right: 104px;
                }
            }
            .code{
                .name{
                    align-items: center;
                    margin-right: 15px;
                }
            }
            .btn{
                margin-left: 74px;
                width: 300px;
                margin-top: 30px;
            }
        }
        .third-step{
            .password{
                margin-bottom: 20px;
                align-items: center;
                .title5{
                    margin-right: 43px;
                }
            }
            .again-password{
                align-items: center;
                .title5{
                    margin-right: 15px;
                }
            }
            .btn{
                margin-left: 74px;
                width: 300px;
                margin-top: 30px;
            }
        }
    }
}
</style>